<mat-drawer-container>
  <mat-drawer-content>
    <!-- NASLOV -->
    <div class="row" style="margin: auto; padding: 25px;">
      <div class="d-none d-sm-block col-sm-3"></div>
      <div class="col-1">
        <img routerLink="/user/dashboard" style="cursor: pointer"
             src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FbackIcon.png?alt=media&token=d3776f59-033a-4d06-b5f3-ba9e925bc495"
             alt="faq page image">
      </div>
      <div class="col-3 col-sm-4" style="margin: auto; text-align: center">
        <h3 class="naslov"
            style="color: #0E0D35;font-family: Lato, 'Alegreya Sans', 'Alegreya Sans'; font-size: 30px; font-weight: bold;">
          Додатна упуства за сертификоване геодетске стручњаке</h3>
      </div>
      <div class="col-9 col-sm-3" style="text-align: right;">
        <!-- <span style = "cursor: pointer; padding-right: 15px;" routerLink = "/user/calendar">
          <img src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FcalendarIcon.png?alt=media&token=0dcbbd8e-d153-4851-af32-658f01446fbd">
        </span> -->

        <span style="cursor: pointer; vertical-align: middle; margin-right: 10px;" routerLink="/user/faq">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webImgs%2FhomeIcon1.png?alt=media&token=f55962fc-39ce-44f5-a7f2-139e4e71c0ea"
          alt="faq icon">
          <button (click)="logout()"
                  style="background-color: #f1f1f1; vertical-align: middle; font-family: Lato, 'Alegreya Sans'; color: #0E0D35; font-size: 16px; padding: 7px; border: 1px solid #f1f1f1; border-radius: 8px;">
        <mat-icon style="vertical-align: middle; color: #0E0D35;">exit_to_app</mat-icon>
        <span class="mobHidden">{{ labels.lblLogOut }}</span>
      </button>
      </span>


      </div>
    </div>
    <div class="coverImageWrap">
      <img class="coverImage" style="width: 100%;"
           src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/Naslovna-fotka.png?alt=media&token=0efd5995-03be-43a0-8fb5-5990c9c4c427">
    </div>
    <div class="webContainer">
      <div class="flex-link-list">
        <div>
          <a href="/assets/Uputstvo1/Uputstvo za instalaciju Modula za geodetske organizacije.pdf"><h2>Упутство за
            инсталацију Модула за геодетске организације (преузми)</h2></a>
        </div>
        <div>
          <h2>Упутство за рад у Модулу за геодетске организације (МГО)</h2>
          <ul>
            <a href="/assets/Uputstvo2/Upustvo%20za%20rad%20u%20Editoru%20MGO-a%20sa%20predmetima%20iz%20DQI-a.pdf"
               download>
              <li>Упутство за рад у Едитору МГО-а са предметима из ДQI-а (преузми)</li>
            </a>
            <a href="/assets/Uputstvo2/Upustvo%20za%20rad%20u%20Editoru%20MGO-a%20sa%20predmetima%20iz%20ISKN-a.pdf"
               download>
              <li>Упутство за рад у Едитору МГО-а са предметима из ИСКН-а (преузми)</li>
            </a>
            <a href="/assets/Uputstvo2/Uputstvo%20MGO_ažurirano%2027092024.pdf" download>
              <li>Упутство МГО ажурирано 27092024 (преузми)</li>
            </a>
            <a href="/assets/Uputstvo2/Uputstvo%20MGO_UVOD.pdf" download>
              <li>Упутство МГО УВОД (преузми)</li>
            </a>
            <a href="/assets/Uputstvo2/Uputstvo%20za%20numeraciju%20graničkih%20tačaka%20u%20MGO-u.pdf" download>
              <li>Упутство за нумерацију граничких тачака у МГО-у (преузми)</li>
            </a>
          </ul>
        </div>
      </div>

    </div>
  </mat-drawer-content>
</mat-drawer-container>
